<template>
  <div>
    <draggable
      class="dragArea dragAreaParent"
      tag="ul"
      :list="tasks"
      :child="child"
      :group="{ name: 'g1' }"
      :sort="true"
      :indexChild="0"
      @start="drag = true"
      @end="drag = false"
      ghost-class="ghost"
      :move="checkMove"
    >
      <li
        v-for="(item, ind) in tasks"
        :key="item + ind"
        :class="
          item.is_group && item.page.length > 1 ? 'itemParent' : 'itemChild'
        "
      >
        <div v-if="!child && item.is_group === 1">
          <div
            class="card table-of-contentedit"
            header="目次"
            header-tag="header"
            v-on:mouseenter="x_state = 1"
            v-on:mouseleave="x_state = 0"
          >
            <div
              class="card-header d-flex flex-nowrap table-of-content-chapter"
            >
              <input
                class="form-input w-100 mr-4"
                type="text"
                v-model="item.title"
                placeholder=""
              />
              <b-icon
                icon="gear-fill"
                class="my-auto"
                font-scale="2"
                style="border-color: #ced2d8; padding: 4px"
                v-on:click="showConditionGroup(ind)"
              />
              <b-icon
                icon="x"
                :class="x_state_fn(x_state) ? x_show : x_hide"
                font-scale="2"
                style="
                  background-color: #ced2d8;
                  border-color: #ced2d8;
                  padding: 4px;
                  border-radius: 50px;
                  right: -1%;
                  top: -10px;
                  cursor: pointer;
                "
                v-on:click="deleteGroup(ind)"
              />
            </div>
            <div class="card-body boder-body">
              <nested-draggable
                v-if="item.page"
                :state1="x_state"
                :tasks="item.page"
                :child="true"
                :indexChild="ind"
                :itemParen="item"
                class="dragArea dragAreaChild"
                :move="checkMove"
              >
              </nested-draggable>
              <div>
                <b-button
                  class="custom-button custom-button-plus mx-auto custom-button-add-chapter"
                  href="#"
                  variant="primary"
                >
                  <b-icon
                    icon="plus"
                    aria-hidden="true"
                    style="font-size: 60px"
                    @click="addPage(ind)"
                  ></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!child && item.is_group === 0">
          <div
            v-for="(ele, index) in item.page"
            v-on:mouseenter="x_state_1 = 1"
            v-on:mouseleave="x_state_1 = 0"
            :key="index"
            class="d-flex flex-direction-column align-items-center mb-4 w-50"
          >
            <div class="btn-page-custom position-relative mr-2 w-100">
              <div class="my-1" style="min-height: 20px">
                {{ Number(ind + 1) + " ページ目 : " + ele.title }}
              </div>
              <b-icon
                icon="x"
                :class="x_state_fn(x_state) ? x_show : x_hide"
                font-scale="2"
                style="
                  border-color: #ced2d8;
                  padding: 0px;
                  border-radius: 50px;
                  right: -10px;
                  top: -10px;
                  cursor: pointer;
                  font-size: 200%;
                  background: #ced2d8;
                  color: #000;
                  width: 20px;
                  height: 20px;
                "
                v-on:click="deletePage(ind, index)"
              />
            </div>
            <b-icon
              icon="gear-fill"
              class="my-auto"
              font-scale="2"
              style="border-color: #ced2d8; padding: 4px; width: 28px"
              v-on:click="showSettingPage(ind, index)"
            />
          </div>
        </div>
        <div v-if="child">
          <div
            class="d-flex flex-direction-column align-items-center mb-4 w-50"
            v-on:mouseenter="x_state_1 = 1"
            v-on:mouseleave="x_state_1 = 0"
          >
            <div class="btn-page-custom position-relative mr-2 w-100">
              <div class="my-1" style="min-height: 20px">
                {{ Number(ind + 1) + " ページ目 : " + item.title }}
              </div>
              <b-icon
                icon="x"
                :class="x_state_fn(state1) ? x_show : x_hide"
                font-scale="2"
                style="
                  border-color: #ced2d8;
                  padding: 0px;
                  border-radius: 50px;
                  right: -10px;
                  top: -10px;
                  cursor: pointer;
                  font-size: 200%;
                  background: #ced2d8;
                  color: #000;
                  width: 20px;
                  height: 20px;
                "
                v-on:click="deletePage(indexChild, ind)"
              />
            </div>
            <b-icon
              icon="gear-fill"
              class="my-auto"
              font-scale="2"
              style="border-color: #ced2d8; padding: 4px"
              v-on:click="showSettingPage(indexChild, ind)"
            />
          </div>
        </div>
      </li>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { eventBus } from "../../main";

export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    state1: {
      required: true,
      type: Number,
    },
    child: {
      type: Boolean,
      default: false,
    },
    indexChild: {
      type: Number,
      default: 1,
    },
    itemParen: {
      type: Object,
    },
  },
  components: {
    draggable,
  },
  name: "nested-draggable",
  data() {
    return {
      x_state: 0,
      x_state_1: 0,
      x_state_2: 0,
      x_show: "my-auto position-absolute",
      x_hide: "my-auto position-absolute d-none",
      itemNoPage: {},
      futureIndex: null,
      objectIndex: null,
    };
  },
  created() {},
  watch: {},
  methods: {
    checkMove(evt) {
      if (
        evt.dragged._prevClass === "itemParent" &&
        evt.to.classList.contains("dragAreaChild")
      ) {
        return false;
      } else {
        const topDrag = evt.draggedRect.top;
        const toprelatedRect = evt.relatedRect.top;
        this.objectIndex =
          topDrag > toprelatedRect
            ? evt.draggedContext.index
            : evt.draggedContext.index - 1;
        this.futureIndex = evt.draggedContext.futureIndex;
      }
    },
    x_state_fn(int) {
      return int === 1 ? true : false;
    },
    deleteGroup(ind) {
      eventBus.$emit("deleteGroup", ind);
    },
    showConditionGroup(ind) {
      eventBus.$emit("showConditionGroup", ind);
    },
    updateConditionGroup() {
      eventBus.$emit("updateConditionGroup");
    },
    addPage(ind) {
      eventBus.$emit("addPage", ind);
    },
    showSettingPage(ind, indexPage) {
      eventBus.$emit("showSettingPage", ind, indexPage);
    },
    deletePage(ind, indexPage) {
      eventBus.$emit("deletePage", ind, indexPage);
    },
  },
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  /* outline: 1px dashed; */
  list-style: none;
  padding: 0;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #d9d9d9;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #f2f2f2;
}
pre {
  text-align: start;
}
.custom-button-add-chapter {
  margin: 20px 0px;
  background-color: #00b797;
  border-color: #00b797;
  color: #fff;
}

.bi-x {
  display: block !important;
}
</style>
